import ConstantTool from "@/services/tool/ConstantTool"
import Response from "@/models/responses/Response"
import SessionModule from "@/store/SessionModule"
import {getModule} from "vuex-module-decorators"
import JsonTool from "@/services/tool/JsonTool"
import Client from "@/models/Client"
import axios from "axios"
import User from "@/models/User";
import Order from "@/models/Order";

export default class HistoryDataService {
    static async findClients(page: number, size: number, search: Nullable<string>): Promise<Response<Client[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/history-data/clients", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { page, size, search }
            })
            const clients = JsonTool.jsonConvert.deserializeArray(response.data, Client)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: clients, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async findCommercials(page: number, size: number, search: Nullable<string>): Promise<Response<User[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/history-data/commercials", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { page, size, search }
            })
            const users = JsonTool.jsonConvert.deserializeArray(response.data, User)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: users, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async findOrders(page: number, size: number, search: Nullable<string>): Promise<Response<Order[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/history-data/orders", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { page, size, search }
            })
            const orders = JsonTool.jsonConvert.deserializeArray(response.data, Order)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: orders, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }
}