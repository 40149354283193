
























































































































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import Address, { AddressType, AddressSelect } from "@/models/Address";
import AddressService from "@/services/AddressService";
import { getModule } from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Client from "@/models/Client";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";
import AddressDialog from "@/components/dialog/AddressDialog.vue";
import CreateAddressDialog from "@/components/dialog/CreateAddressDialog.vue";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";
import HistoryDataService from "@/services/HistoryDataService";
import Order from "@/models/Order";
import OrderStatusComponent from "@/components/OrderStatusComponent.vue";

@Component({ components: { OrderStatusComponent } })
export default class OrderHistoryTable extends Vue {

  lang: any = getModule(LangModule).lang
  dialog: boolean = false
  loading: boolean = false
  page = 1
  size = 7
  clients: MultipleItem<Client> = { items: [], totalItems: 0 }
  orders: MultipleItem<Order> = { items: [], totalItems: 0 }
  pageCount = 0

  headers = [
    { text: "ID", value: "id", width: "80", align: "center" },
    {align: 'center', width: '150px', text: "Xgest ID", value: 'ref'},
    {align: 'center', width: '130px', text: this.lang.status, value: 'status' },
    {align: 'center', width: 'auto', text: this.lang.client, value: 'client'},
    {align: 'center', width: 'auto', text: this.lang.companies, value: 'company'},
    { text: this.lang.createdBy, value: "createdBy", width: "auto", align: "center" },
    { text: this.lang.date, value: "createdAt", width: "auto", align: "center" }
  ]

  created() { this.refresh() }

  async refresh() {
    try {
      await Handler.getItems(this, this.orders, () =>
          HistoryDataService.findOrders(this.page - 1,this.size, null)
      )
      this.pageCount = Math.ceil(this.orders.totalItems!! / this.size)
    } catch (e) { console.log(e) }

  }

  @Watch('page')
  onPageChanged() { this.refresh() }

}
